<template>
  <div class="dashboard-inner-page">
    <div v-if="isMobile && currentPageSubPage === 'company'">
      <div
        class="dashboard-inner-page--sub-step"
        @click="openLogo()"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Company logotype</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Upload or change your company logotype</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
      <div
        class="dashboard-inner-page--sub-step"
        @click="this.$emit('update:subStep', 'company-information')"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Company information</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Review the data that you’ve provided, and make changes if you’d like</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
      <div
        class="dashboard-inner-page--sub-step"
        @click="this.$emit('update:subStep', 'company-location')"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Company location</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Information about your company location that sees your customer</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
      <div
        v-if="getCurrentUser?.type === 'Contractor'"
        class="dashboard-inner-page--sub-step"
        @click="this.$emit('update:subStep', 'company-type')"
      >
        <div class="dashboard-inner-page--sub-step--text">
          <p class="dashboard-inner-page--sub-step--text--title text-medium">Trade Type</p>
          <p class="dashboard-inner-page--sub-step--text--description h6-light">Please choose you trade type</p>
        </div>
        <inline-svg
          :src="require('@/assets/images/icons/arrow-left.svg')"
          class="dashboard-inner-page--sub-step--icon"
        />
      </div>
    </div>

    <div
      v-if="!isMobile || currentPageSubPage === 'company-logotype'"
      class="dashboard-inner-page--sub-step-body company-logotype"
      :class="{'open': showSubStep === 'company-logotype'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="openLogo()"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Company logotype</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Upload or change your company logotype</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'company-logotype' ? 'Close' : buttonText}}
        </button>
      </div>

      <div class="dashboard-inner-page--sub-step-body--desktop-body">
        <CustomInputUploader
          :modelValue="file"
          :filePreview="filePreview"
          @update:modelValue="handleUploadFileChange"
          @update:filePreview="handleUploadFilePreviewChange"
          @handleFileError="handleUploadFileError"
          :description="description"
          @update:isLogoDeleted="handleIsLogoDeleted"
          :isUpdating="isUpdating"
          isUpdate
          :disable="!canEdit"
        />
        <button
          v-if="canEdit"
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[isUpdateButtonDisabled && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdateContractorInfo"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>
    </div>

    <div
      v-if="!isMobile || currentPageSubPage === 'company-information'"
      class="dashboard-inner-page--sub-step-body"
      :class="{'open': showSubStep === 'company-information'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="this.$emit('update:subStep', 'company-information')"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Company information</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Review the data that you’ve provided, and make changes if you’d like</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'company-information' ? 'Close' : buttonText}}
        </button>
      </div>

      <div
        v-if="canEdit"
        class="dashboard-inner-page--sub-step-body--desktop-body"
      >
        <CustomInput
          :modelValue="contractorCompany.name"
          @update:modelValue="handleCompanyNameChange"
          label="Company name"
          id="company-name"
          type="text"
          name="company-name"
          :errorMessage="companyNameError"
        />
        <CustomInput
          :modelValue="contractorCompany.contact_name"
          @update:modelValue="handleContactNameChange"
          label="Contact name"
          id="contact-name"
          type="text"
          name="contact-name"
          :errorMessage="contactNameError"
        />
        <custom-input
          :modelValue="contractorCompany.phone ? contractorCompany.phone : ''"
          @update:modelValue="handleCompanyPhoneNumberChange"
          label="Phone number"
          id="company-phone-number"
          type="tel"
          name="company-phone-number"
          :marginBottom="isMobile ? '60' : '30'"
          :errorMessage="companyPhoneNumberError"
        />
        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[isUpdateButtonDisabled && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdateContractorInfo"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>

      <div
        v-else
        class="dashboard-inner-page--sub-step-body--desktop-body"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">Company name</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.name }}
          </p>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">Contact name</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.contact_name }}
          </p>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">Phone number</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.phone ? contractorCompany.phone : '' }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!isMobile || currentPageSubPage === 'company-location'"
      class="dashboard-inner-page--sub-step-body"
      :class="{'open': showSubStep === 'company-location'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="this.$emit('update:subStep', 'company-location')"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Company location</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Information about your company location that sees your customer</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'company-location' ? 'Close' : buttonText}}
        </button>
      </div>

      <div
        v-if="canEdit"
        class="dashboard-inner-page--sub-step-body--desktop-body"
      >
        <CustomInput
          :modelValue="contractorCompany.city"
          @update:modelValue="handleCompanyCityChange"
          label="City"
          id="company-city"
          type="text"
          name="company-city"
          :errorMessage="companyCityError"
        />
        <CustomInput
          :modelValue="contractorCompany.state"
          @update:modelValue="handleCompanyStateChange"
          type="text"
          label="State"
          id="state"
          name="state"
          :errorMessage="companyStateError"
        />
        <CustomInput
          :modelValue="contractorCompany.zip_code"
          @update:modelValue="handleCompanyZipChange"
          label="Zip"
          id="company-zip"
          type="text"
          name="company-zip"
          :errorMessage="companyZipError"
        />
        <CustomInput
          :modelValue="contractorCompany.address_line_1"
          @update:modelValue="handleAddressOneChange"
          label="Address 1"
          id="address-one"
          type="text"
          name="address-one"
          :errorMessage="addressOneError"
        />
        <CustomInput
          :modelValue="contractorCompany.address_line_2"
          @update:modelValue="handleAddressTwoChange"
          label="Address 2"
          id="address-two"
          type="text"
          name="address-two"
          :errorMessage="''"
          optional
          :marginBottom="isMobile ? '60' : '30'"
        />
        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[isUpdateButtonDisabled && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdateContractorInfo"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>

      <div
        v-else
        class="dashboard-inner-page--sub-step-body--desktop-body"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">City</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.city }}
          </p>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">State</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.state }}
          </p>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">Zip</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.zip_code }}
          </p>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">Address 1</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.address_line_1 }}
          </p>
        </div>
        <div class="dashboard-inner-page--sub-step-body--desktop-body--item">
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--title label">Address 2</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-body--item--text h6-light">
            {{ contractorCompany.address_line_2 }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!isMobile && getCurrentUser?.type === 'Contractor'
        || getCurrentUser?.type === 'Contractor' && currentPageSubPage === 'company-type'"
      class="dashboard-inner-page--sub-step-body"
      :class="{'open': showSubStep === 'company-type'}"
    >
      <div
        v-if="!isMobile"
        class="dashboard-inner-page--sub-step-body--desktop-header"
        @click="this.$emit('update:subStep', 'company-type')"
      >
        <div class="dashboard-inner-page--sub-step-body--desktop-header--text">
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--title text-medium">Trade Type</p>
          <p class="dashboard-inner-page--sub-step-body--desktop-header--text--description h6-light">Please choose you trade type</p>
        </div>
        <button class="dashboard-inner-page--sub-step-body--desktop-header--button btn-font-large">
          {{showSubStep === 'company-type' ? 'Close' : buttonText}}
        </button>
      </div>

      <div class="dashboard-inner-page--sub-step-body--desktop-body">
        <CustomCheckGroup
          id="company-type"
          :modelValue="selectedTradeTypes"
          @update:modelValue="handleTradeTypeChange"
          :options="tradeTypesOptions"
          label=""
          :errorMessage="null"
        />
        <button
          class="dashboard-inner-page--sub-step-body--button normal-btn"
          :class="[isUpdateButtonDisabled && 'disabled',
                  isUpdating && 'frozen']"
          @click="handleUpdateContractorInfo"
        >{{ isUpdating ? 'Updated!' : 'Update' }}</button>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { validationUtils } from '@/utils/validation';

import InlineSvg from 'vue-inline-svg';
import CustomInput from '@/components/shared/CustomInput';
import CustomInputUploader from '@/components/shared/CustomInputUploader';
import CustomCheckGroup from '@/components/shared/CustomCheckGroup';

export default {
  components: {
    InlineSvg,
    CustomInput,
    CustomInputUploader,
    CustomCheckGroup
  },

  props: [
    'contractorCompany',
    'currentPageSubPage',
    'subStep',
    'showSubStep',
    'handleUpdateContractorInfo',
    'isMobile',
    'file',
    'filePreview',
    'description',
    'handleUploadFileChange',
    'handleUploadFilePreviewChange',
    'handleUploadFileError',
    'isLogoDeleted',
    'handleIsLogoDeleted',
    'companyName',
    'handleCompanyNameChange',
    'companyNameError',
    'contactName',
    'handleContactNameChange',
    'contactNameError',
    'companyPhoneNumber',
    'handleCompanyPhoneNumberChange',
    'companyPhoneNumberError',
    'companyCity',
    'handleCompanyCityChange',
    'companyCityError',
    'companyZip',
    'handleCompanyZipChange',
    'companyZipError',
    'addressOne',
    'handleAddressOneChange',
    'addressOneError',
    'addressTwo',
    'handleAddressTwoChange',
    'companyState',
    'companyStateError',
    'selectOptions',
    'handleCompanyStateChange',
    'isUpdating',
    'tradeTypesOptions',
    'selectedTradeTypes',
    'handleTradeTypeChange',
    'isCompanyTypeChanged'
  ],

  mounted() {
    this.$emit('update:currentPageSubPage', 'company');
  },

  methods: {
    openLogo() {
      window.location.hash = 'camera';

      this.$emit('update:subStep', 'company-logotype');
    },
  },

  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),

    isUpdateButtonDisabled() {
      if (this.currentPageSubPage === 'company-logotype') {
        return (!this.isLogoDeleted && !this.file);
      } else if (this.currentPageSubPage === 'company-information') {
        return (!this.companyName
          && !this.contactName
          && this.contractorCompany.phone === validationUtils.getFormattedPhoneNumber(this.companyPhoneNumber));
      } else if (this.currentPageSubPage === 'company-location') {
        return (!this.companyCity
          && !this.companyState
          && !this.companyZip
          && !this.addressOne
          && !this.addressTwo.length);
      } else if (this.currentPageSubPage === 'company-type') {
        return !this.isCompanyTypeChanged;
      }
      return true
    },

    canEdit() {
      return this.getCurrentUser &&
            (this.getCurrentUser?.role === 'admin' || this.getCurrentUser?.role === 'owner');
    },

    buttonText() {
      return this.canEdit ? 'Edit' : 'Open';
    },

    getStateText() {
      return this.selectOptions.filter(item => item.value === this.contractorCompany.state);
    }
  }
}
</script>

<style lang="scss">
.dashboard-inner-page {
  margin-top: 5px;
  padding-bottom: 50px;

  &--sub-step {
    padding: 20px 25px;
    max-width: 325px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F0F3FF;
    cursor: pointer;

    &:first-child {
      padding: 0 25px 20px;
    }

    &--text {
      max-width: 290px;

      &--title  {
        margin-bottom: 6px;
        color: #151522;
      }

      &--description  {
        color: #81878C;
      }
    }

    &--icon {
      margin-right: 12px;
      width: 16px;
      height: 16px;
      transform: rotate(180deg) translateX(-100%);
    }
  }

  &--sub-step-body {
    padding: 0 25px;

    &.company-logotype {
      padding-top: 108px;
    }

    &--desktop-header {
      &--text {
        .single-step & {
          margin-bottom: 42px;
        }

        &--description {
          .single-step & {
            color: #81878C;
            font-weight: 300;
            line-height: 150%;
          }
        }
      }
    }

    &--desktop-body {
      &--link {
        color: #151522;
        text-decoration: none;
      }

      &--item {
        margin-bottom: 14px;

        &.role {
          max-width: 104px;
        }

        &--title {
          color: #151522;
          margin-bottom: 6px;
        }

        &--text {
          color: #151522;

          &.role {
            text-transform: capitalize;
          }
        }
      }

      &--title {
        margin-bottom: 20px;
        color: #151522;

        &.custom {
          margin-bottom: 14px;
        }
      }

      &--list {
        margin-bottom: 30px;

        &.custom {
          margin-bottom: 0;
        }

        &--item {
          margin-bottom: 14px;

          &.custom {
            margin-bottom: 4px;

            &:last-child {
              margin-bottom: 10px;
            }
          }

          &--text {
            padding: 6px;
            margin-left: -6px;
            border-radius: 4px;
            transition: .3s;
            cursor: pointer;

            &:hover {
              background: #F8FBFE;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &--add-btn {
        display: flex;
        align-items: center;
        border: 0px;
        background: #fff;
        padding: 0;
        margin: 0 0 60px 4px;

        &--icon {
          width: 12px;
          height: 12px;
          margin-right: 10px;
        }
      }

      &--add-item {
        margin-bottom: 30px;
        position: relative;

        &--wrapper {
          border: 1px solid #CCCCD7;
          box-sizing: border-box;
          border-radius: 5px;
          background: #FFFFFF;
          padding: 10px 20px;
          width: 100%;
          position: relative;

          &.error-wrapper {
            border: 1px solid #E83C56;
          }

          &--textarea {
            color: #151522;
            box-sizing: border-box;
            font-size: 16px;
            line-height: 150%;
            width: 100%;
            height: 21px;
            font-family: $SFProText;
            font-weight: 300;
            box-shadow: none;
            border: none;
            resize: none;
            overflow: auto;
            padding: 0;
            margin-bottom: 16px;
          }

          &--btn-block {
            display: flex;
            align-items: center;
            position: relative;

            &--button {
              width: 103px;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 20px;
            }

            &--icon {
              cursor: pointer;
            }

            &--delete {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 2px;
              width: 15px;
              height: 17px;
              cursor: pointer;
            }
          }
        }

        &--error {
          font-size: 12px;
          color: #E83C56;
          text-align: left;
          font-family: $SFProText;
          font-weight: 500;
          line-height: 150%;
          position: absolute;
          bottom: -22px;
          left: 0;
        }
      }
    }

    &--button {
      padding: 13px 0;
      max-width: 325px;
      width: 100%;
      margin: 0 auto;
      display: block;

      &.frozen {
        pointer-events: none;
      }

      .company-logotype & {
        margin-top: 159px;
      }
    }

    &--help {
      display: block;
      text-align: right;
      width: 100%;
      max-width: 325px;
      margin: 0 auto 18px;

      &--link {
        text-decoration: none;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .dashboard-inner-page {
    margin: 0 60px;

    &--sub-step-body {
      padding: 20px 0;
      border-top: 1px solid rgba(92, 92, 102, 0.4);

      &--button {
        display: none;

        .open & {
          display: block;
        }
      }

      &.open {
        border-top: 2px solid #10886D;

        .single-step & {
          border-top: 1px solid rgba(92, 92, 102, 0.4);
        }
      }

      &.company-logotype {
        padding-top: 20px;
      }

      &:last-child {
        border-bottom: 1px solid rgba(92, 92, 102, 0.4);

        .single-step & {
          border-bottom: unset;
          width: 100%;
        }
      }

      &--desktop-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .single-step & {
          cursor: default;
        }

        &--text {
          &--title {
            margin-bottom: 6px;
            color: #151522;
          }

          &--description {
            color: #81878C;

          }
        }

        &--button {
          border: none;
          background: none;
          color: #151522;
          padding: 0;

          .open & {
            color: #10886D;
          }
        }
      }

      &--button {
        max-width: 240px;
        margin: 0;

        .company-logotype & {
          margin-top: 30px;
        }
      }

      &--help {
        max-width: 397px;
      }

      &--desktop-body {
        height: 0;
        overflow: hidden;
        transition: .4s;
        max-width: 397px;
        height: 0;
        opacity: 0;
        transition: .2s;

        &.full-width {
          max-width: 100%;
        }

        &--add-btn {
          margin-bottom: 30px;
        }

        .open & {
          padding-top: 18px;
          height: 100%;
          opacity: 1;
          overflow: visible;

          &.help-page {
            padding-top: 0;
          }
        }

        &.full-width {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
